<template>
  <v-container fluid>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
      min-width="360px"
    >
      <div>
        <v-tabs show-arrows background-color="blue" icons-and-text dark grow>
          <v-tabs-slider color="blue darken-4"></v-tabs-slider>

          <v-tab v-for="i in tabs" :key="i.name">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1">{{ i.name }}</div>
          </v-tab>
          <v-tab-item>
            <v-progress-linear
              v-if="performingRequest"
              indeterminate
              color="blue"
              class="mb-0"
            ></v-progress-linear>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="loginForm" lazy-validation>
                  <v-row dense>
                    <v-col cols="12">
                      <v-alert v-if="error" type="error">
                        {{ error }}
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="email"
                        dense
                        type="email"
                        label="E-mail"
                        required
                        :rules="[validateLength(email, 50)]"
                        @keydown="error = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="password"
                        dense
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        hint="At least 8 characters"
                        counter
                        :rules="[validateLength(password, 50)]"
                        @keydown="error = null"
                        @click:append="showPassword = !showPassword"
                        @keyup.enter="login"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                      <v-btn
                        block
                        color="success"
                        @keyup.enter="login"
                        @click="login"
                      >
                        Login
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-progress-linear
              v-if="performingRequest"
              indeterminate
              color="blue"
              class="mb-0"
            ></v-progress-linear>
            <v-card class="px-4">
              <v-card-text>
                <div class="body-1">
                  In order to <strong>protect your account</strong>, make sure
                  your password:
                </div>
                <div>
                  <ul>
                    <li>Is longer than 8 characters</li>
                    <li>Contains upper and lower case characters</li>
                    <li>Contains at least 1 digit</li>
                    <li>Contains at least 1 symbols</li>
                    <li>Is not a common password</li>
                  </ul>
                </div>
              </v-card-text>
              <v-card-text>
                <v-form ref="registerForm" lazy-validation>
                  <v-row dense>
                    <v-col cols="12">
                      <v-alert v-if="error" type="error">
                        {{ error }}
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="username"
                        dense
                        label="Name"
                        required
                        :rules="[
                          validateHTML(username),
                          validateLength(username, 50),
                          validateCharacters(username)
                        ]"
                        @keydown="error = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="email"
                        dense
                        type="email"
                        label="E-mail"
                        required
                        :rules="[validateLength(email, 50)]"
                        @keydown="error = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="password"
                        dense
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        hint="At least 8 characters"
                        :rules="[validateLength(password, 50)]"
                        counter
                        @click:append="showPassword = !showPassword"
                        @keydown="error = null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      {{ passwordStrength }}
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                      <v-btn
                        block
                        color="success"
                        @keyup.enter="signup"
                        @click="signup"
                        >Register</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-progress-linear
              v-if="performingRequest"
              indeterminate
              color="blue"
              class="mb-0"
            ></v-progress-linear>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="registerForm" lazy-validation>
                  <v-row dense>
                    <v-col cols="12">
                      <v-alert v-if="error" type="error">
                        {{ error }}
                      </v-alert>
                      <v-alert v-if="passwordResetSuccess" type="success">
                        <h3>Email sent.</h3>
                        Please check your email for a link to reset your
                        password
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <p v-if="!passwordResetSuccess" class="body-1">
                        We will send you an email to reset your password
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-if="!passwordResetSuccess"
                        v-model.trim="email"
                        dense
                        type="email"
                        label="E-mail"
                        required
                        :rules="[validateLength(email, 50)]"
                        @keydown="error = null"
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                      <v-btn
                        v-if="!passwordResetSuccess"
                        block
                        color="success"
                        @keyup.enter="reset"
                        @click="reset"
                        >Reset</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import * as inputValidationRules from '@/components/inputValidationRules'
import { PreferencesPrototype } from '@/components/prototypes/preferences'
import { NotificationsPrototype } from '@/components/prototypes/notifications'
const Firebase = require('@/firebaseConfig.js')
export default {
  name: 'Login',
  data() {
    const data = {
      username: '',
      password: '',
      email: '',
      error: null,
      performingRequest: false,
      showDialog: true,
      showPassword: false,
      passwordResetSuccess: false,
      tabs: [
        { name: 'Login', icon: 'mdi-account' },
        { name: 'Register', icon: 'mdi-account-outline' },
        { name: 'Reset', icon: 'mdi-account-question-outline' }
      ],
      initialPreferences: {}
    }
    return { ...data, ...inputValidationRules }
  },
  computed: {
    passwordStrength() {
      let taiPasswordStrength = require('tai-password-strength')
      let strengthTester = new taiPasswordStrength.PasswordStrength()
      let strength = strengthTester.check(this.password).strengthCode
      if (strength === null) return 'Password strength:'
      return 'Password strength: ' + strength.replace('_', ' ').toLowerCase()
    }
  },
  created: function () {
    this.initialPreferences = new PreferencesPrototype()
  },
  methods: {
    ...mapActions('modulePreferences', [
      'PreferencesUpdateAction',
      'PreferencesReadAction'
    ]),
    reset() {
      this.performingRequest = true
      Firebase.auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.performingRequest = false
          this.passwordResetSuccess = true
          this.email = ''
        })
        .catch((_error) => {
          this.error =
            "That didn't work, please fix any mistakes and try again."
          new NotificationsPrototype().create(
            'Login',
            'critical',
            `Error while logging in: ${_error.message}`
          )
          this.performingRequest = false
        })
    },
    login() {
      this.performingRequest = true
      Firebase.auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then((_user) => {
          this.$store.commit('moduleUser/SetCurrentUser', _user.user)
          this.$store.dispatch('moduleUser/UserProfileReadAction')
          this.PreferencesReadAction()
          new NotificationsPrototype().create(
            'Login',
            'information',
            `User ${_user.user.email} logged in with UID ${
              _user.user.uid
            } at ${Date(_user.user.metadata.lastSignInTime).toLocaleString()}`
          )
          this.performingRequest = false
          this.$router.push('/', () => {})
        })
        .catch((_error) => {
          this.error =
            "That didn't work, please fix any mistakes and try again."
          new NotificationsPrototype().create(
            'Login',
            'critical',
            `Error while logging in: ${_error.message}`
          )
          this.performingRequest = false
        })
    },
    signup() {
      this.performingRequest = true
      let rejectMessage = null
      let taiPasswordStrength = require('tai-password-strength')
      let passwordValidator = require('password-validator')
      let strengthTester = new taiPasswordStrength.PasswordStrength()
      let schema = new passwordValidator()
      schema
        .is()
        .min(8)
        .is()
        .max(50)
        .has()
        .uppercase()
        .has()
        .lowercase()
        .has()
        .digits()
        .has()
        .symbols()
      if (schema.validate(this.password) === false)
        rejectMessage =
          'New password rejected because it does not meet the password policy critera: ' +
          schema.validate(this.password, { list: true })
      if (strengthTester.check(this.password).commonPassword === true)
        rejectMessage =
          'New password rejected because it does not meet the password policy critera: common password'
      if (rejectMessage) {
        this.error = rejectMessage
        new NotificationsPrototype().create(
          'Login',
          'critical',
          `Error while logging in: ${rejectMessage}`
        )
        this.password = null
        this.performingRequest = false
        return
      }
      Firebase.auth
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((_user) => {
          this.PreferencesUpdateAction(this.initialPreferences)
          Firebase.auth.currentUser.sendEmailVerification()
          this.$store.commit('moduleUser/SetCurrentUser', _user.user)
          Firebase.usersCollection
            .doc(_user.user.uid)
            .set({
              name: this.username,
              email: this.email
            })
            .then(() => {
              this.$store.dispatch('moduleUser/UserProfileReadAction')
              this.performingRequest = false
              this.$router.push('/', () => {})
            })
            .catch((_error) => {
              this.error =
                "That didn't work, please fix any mistakes and try again."
              new NotificationsPrototype().create(
                'Login',
                'critical',
                `Error while logging in: ${_error.message}`
              )
              this.performingRequest = false
            })
        })
        .catch((_error) => {
          this.error =
            "That didn't work, please fix any mistakes and try again."
          new NotificationsPrototype().create(
            'Login',
            'critical',
            `Error while logging in: ${_error.message}`
          )
          this.performingRequest = false
        })
    }
  }
}
</script>
